import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { messages } from 'config/messages';

const isNotEmptyString = string => string && typeof string === 'string';

const normalize = message => {
  if (!message) {
    return '';
  } else if (typeof message === 'string') {
    return message;
  } else {
    // attempt to parse common objects: responses and
    // errors returned by the server in case if the
    // payload was passed instead of the message itself
    if (
      message.data &&
      message.data.result &&
      message.data.result.errors &&
      Array.isArray(message.data.result.errors) &&
      message.data.result.errors.length > 0
    ) {
      return message.data.result.errors.map(error => error.msg);
    } else if (message.data && isNotEmptyString(message.data.message)) {
      return message.data.message;
    } else if (message.response && message.response.data) {
      if (isNotEmptyString(message.response.data.error)) {
        return message.response.data.error;
      } else if (
        message.response.data.error &&
        isNotEmptyString(message.response.data.error.message)
      ) {
        return message.response.data.error.message;
      } else if (isNotEmptyString(message.response.data.message)) {
        return message.response.data.message;
      } else {
        return '';
      }
    } else if (message.error && isNotEmptyString(message.error.message)) {
      return message.data.message;
    } else if (isNotEmptyString(message.error)) {
      return message.error;
    } else {
      return '';
    }
  }
};

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showToastNotification = useCallback(
    (message, fallbackMessage, options) => {
      if (Array.isArray(message)) {
        message.forEach(text => {
          const normalizedText = normalize(text);
          if (Array.isArray(normalizedText) && normalizedText.length > 0) {
            normalizedText.forEach(message => enqueueSnackbar(message));
          } else {
            enqueueSnackbar(normalize(text) || fallbackMessage, options);
          }
        });
      } else {
        enqueueSnackbar(normalize(message) || fallbackMessage, options);
      }
    },
    [enqueueSnackbar],
  );

  const successToast = useCallback(
    (message, fallbackMessage = messages.common.success, options) => {
      showToastNotification(message, fallbackMessage, { ...options, variant: 'success' });
    },
    [showToastNotification],
  );

  const errorToast = useCallback(
    (message, fallbackMessage = messages.common.error, options) => {
      showToastNotification(message, fallbackMessage, { ...options, variant: 'error' });
    },
    [showToastNotification],
  );

  const infoToast = useCallback(
    (message, fallbackMessage, options) => {
      const text = normalize(message);
      if (isNotEmptyString(text) || isNotEmptyString(fallbackMessage)) {
        showToastNotification(text, fallbackMessage, { ...options, variant: 'info' });
      }
    },
    [showToastNotification],
  );

  const warningToast = useCallback(
    (message, fallbackMessage, options) => {
      const text = normalize(message);
      if (isNotEmptyString(text) || isNotEmptyString(fallbackMessage)) {
        showToastNotification(text, fallbackMessage, { ...options, variant: 'warning' });
      }
    },
    [showToastNotification],
  );

  const defaultToast = useCallback(
    (message, fallbackMessage, options) => {
      const text = normalize(message);
      if (isNotEmptyString(text) || isNotEmptyString(fallbackMessage)) {
        showToastNotification(text, fallbackMessage, options);
      }
    },
    [showToastNotification],
  );

  const customToast = useCallback(
    (message, fallbackMessage, options) => {
      const text = normalize(message);
      console.log('options', options);
      if (isNotEmptyString(text) || isNotEmptyString(fallbackMessage)) {
        showToastNotification(text, fallbackMessage, { ...options, variant: 'custom' });
      }
    },
    [showToastNotification],
  );

  return { successToast, errorToast, infoToast, warningToast, defaultToast, customToast };
};

export default useToast;
