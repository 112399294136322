import { flow, types } from 'mobx-state-tree';

import api from 'services/API';
import { arrayUtilities } from 'utils';
import { getRootStore } from 'models/root';
import { Role } from 'models/types/role';
import { UserProfileWithViews } from 'models/types/userProfileWithViews';
import userStatuses from 'config/userStatuses';

export const usersInitialState = {
  all: [],
  roles: [],
  filters: {
    sortBy: 'name',
    orderBy: 'ascending',
    userStatuses: ['active', 'pending'],
    userRoles: null,
  },
  searchString: null,
  isRolesLoaded: false,
  isUsersLoaded: false,
};

const sortByField = {
  name: (a, b) => {
    const userA = a.fullName || a.email;
    const userB = b.fullName || b.email;
    if (userA < userB) {
      return -1;
    } else if (userA > userB) {
      return 1;
    } else {
      return 0;
    }
  },
  role: (a, b) => {
    if (a.roleName < b.roleName) {
      return -1;
    } else if (a.roleName > b.roleName) {
      return 1;
    } else {
      return 0;
    }
  },
  date: (a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at),
};

export const usersModel = types
  .model({
    all: types.array(UserProfileWithViews),
    allRoles: types.array(Role),
    filters: types.maybeNull(
      types.model({
        userStatuses: types.maybeNull(types.array(types.string)),
        userRoles: types.maybeNull(types.array(types.integer)),
        sortBy: types.string,
        orderBy: types.string,
      }),
    ),
    searchString: types.maybeNull(types.string),
    isRolesLoaded: types.boolean,
    isUsersLoaded: types.boolean,
  })
  .views(self => ({
    get areFiltersApplied() {
      return (
        self.filters?.userStatuses.length !== usersInitialState.filters.userStatuses.length ||
        self.filters?.userRoles?.length !== self.roles.length ||
        self.filters?.sortBy !== usersInitialState.filters.sortBy ||
        self.filters?.orderBy !== usersInitialState.filters.orderBy
      );
    },
    get availableUsers() {
      const { userStore } = getRootStore();
      if (!userStore.isBarTrackUser) {
        return self.all
          .slice()
          .filter(user => !user.isBarTrackUser)
          .slice()
          .sort(sortByField.name);
      }
      return self.all.slice().sort(sortByField.name);
    },
    get roles() {
      const { userStore } = getRootStore();
      const currentEstablishmentId = userStore.currentRole._establishment_id;
      const roles = self.allRoles
        .slice()
        .filter(role => role.establishment_id === currentEstablishmentId);
      if (!userStore.isBarTrackUser) {
        return roles.filter(role => !['BarTrack'].includes(role.name));
      }
      return roles;
    },
    get roleIDs() {
      return self.roles.map(role => role.id);
    },
    filteredUsersBySearch: search => {
      let filteredUsers;
      filteredUsers =
        !search || search === ''
          ? self.availableUsers
          : arrayUtilities.filterDataBySearch(search, self.availableUsers, [
              'first_name',
              'last_name',
              'roleName',
              'email',
              'phone_number',
            ]);
      if (search && filteredUsers.length === 0) {
        filteredUsers = [];
      }
      return filteredUsers;
    },

    getRoleNameByID: id => {
      return self.roles.find(role => id === role.id).name;
    },

    getUserById(id) {
      if (id) {
        return self.all.find(user => user.id === id);
      }
      return null;
    },

    get barManagerUsers() {
      let filtered = self.filteredUsersBySearch(self.searchString);
      const userRoles = self.filters.userRoles || self.defaultRoleIds;
      const sorted = filtered
        .filter(
          user =>
            user.roles.some(role => userRoles?.includes(role._role_id)) &&
            self.filters.userStatuses?.includes(user.status?.toLowerCase()),
        )
        .sort(sortByField[self.filters.sortBy]);

      return self.filters.orderBy === 'ascending' ? sorted : sorted.reverse();
    },

    get defaultRoleIds() {
      const barTrackRoleId = self.roles.find(role => role.name === 'BarTrack')?.id;

      return self.roleIDs.filter(id => id !== barTrackRoleId);
    },

    get defaultStatuses() {
      return Object.values(userStatuses).filter(value => value !== 'Locked');
    },

    get activeUsers() {
      return self.all.filter(
        ({
          isBarTrackUser,
          currentEstablishmentRole: { _roleuser_locked, _roleuser_accepted_at },
        }) => !isBarTrackUser && !_roleuser_locked && !!_roleuser_accepted_at,
      );
    },

    get defaultFilters() {
      const { usersStore } = getRootStore();
      return { ...usersInitialState.filters, userRoles: usersStore.defaultRoleIds };
    },

    getFullNameById(id) {
      return self.all.find(user => user.id === id)?.fullName || 'N/A';
    },
  }))
  .actions(self => ({
    fetchRoles: flow(function* () {
      try {
        const roles = yield api.getRoles();
        self.allRoles = roles.data.result;
        self.isRolesLoaded = true;
        return roles.data.result;
      } catch (err) {
        self.isRolesLoaded = false;
        self.allRoles = [];
        return Promise.reject(err);
      }
    }),

    fetchUsers: flow(function* () {
      try {
        const response = yield api.getUsers();
        const users = response.data.result;
        self.all.replace(users);
        self.isUsersLoaded = true;
        return users;
      } catch (err) {
        self.isUsersLoaded = false;
        self.all = [];
        return Promise.reject(err);
      }
    }),

    fetchUser: flow(function* (id, headers) {
      try {
        const response = yield api.getUser(id, { headers });
        const user = response.data.result;
        return user;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    addUserRole: flow(function* (payload) {
      try {
        const response = yield api.addUserRole(payload);

        if (response?.data) {
          yield self.fetchUsers();

          return response?.data;
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    resendRoleInvitation: flow(function* (role) {
      try {
        return yield api.resendRoleInvitation({ role_user_id: role._roleuser_id });
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    cancelRoleInvitation: flow(function* (role) {
      try {
        const response = yield api.cancelRoleInvitation({ role_user_id: role._roleuser_id });

        yield self.fetchUsers();

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    changeUserRoles: flow(function* ({ role_id, id }) {
      try {
        const response = yield api.patchRoleUser({ role_id }, { id });
        self.updateRole(response.data.row);

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    updateUser: flow(function* (userId, payload, isRegistration = false) {
      const { phone_number } = payload;
      const { userStore } = getRootStore();
      try {
        const response = yield api.updateUser(userId, {
          ...payload,
          ...(phone_number && {
            phone_number: phone_number[0] === '+' ? phone_number : `+${phone_number}`,
          }),
        });
        if (
          !isRegistration &&
          response &&
          response.data &&
          response.data.row &&
          response.data.row.id === userStore.profile.id
        ) {
          self.updateOrInsert(response.data.row);
          userStore.setProfile(response.data.row);
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    lockUser: flow(function* (payload) {
      try {
        const response = yield api.lockUserRoles(payload);

        self.updateRoleLock(response.data);

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    updateOrInsert(user) {
      if (user && user.id) {
        const userIndex = self.all.findIndex(
          existingUser => existingUser && existingUser.id === user.id,
        );
        const userExists = userIndex >= 0;

        if (userExists) {
          self.all[userIndex] = user;
        }
      }
    },

    updateRoleLock(data) {
      const userIndex = self.all.slice().findIndex(e => e.id === data.user_id);

      if (userIndex >= 0) {
        const { locked, locked_at, locked_by, role_id } = data;
        const roleIndex = self.all[userIndex]._roles.findIndex(e => e._role_id === role_id);

        if (roleIndex >= 0) {
          Object.assign(self.all[userIndex]._roles[roleIndex], {
            _roleuser_locked: locked,
            _roleuser_locked_at: locked_at,
            _roleuser_locked_by: locked_by,
          });
        }
      }
    },
    updateRole(data) {
      const userIndex = self.all.slice().findIndex(e => e.id === data.user_id);

      if (userIndex >= 0) {
        const { role_id, _roles_name, _establishments_id } = data;
        const roleIndex = self.all[userIndex]._roles.findIndex(
          e => e._establishment_id === _establishments_id,
        );

        if (roleIndex >= 0) {
          Object.assign(self.all[userIndex]._roles[0], {
            _role_id: role_id,
            _role_name: _roles_name,
          });
        }
      }
    },

    setFilters(filters) {
      self.filters = filters;
    },

    resetFilters() {
      self.filters = self.defaultFilters;
    },

    setSearchString(string) {
      self.searchString = string;
    },

    setRoles(roles) {
      self.allRoles = roles;
      self.isRolesLoaded = true;
    },

    setUsers(users) {
      self.all.replace(users);
      self.isUsersLoaded = true;

      const { userStore } = getRootStore();

      const user = users.find(({ id }) => userStore.profile.id === id);
      if (user) {
        userStore.setProfile(user);
      }
    },
  }));
