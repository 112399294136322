import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import {
  Box,
  Button,
  MenuItem,
  Typography,
  MenuList,
  Paper,
  ClickAwayListener,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { useStore, useToast, useMediaQueries } from 'hooks';
import { messages } from 'config/messages';
import SearchField from 'components/shared/SearchField';

const EstablishmentMenu = () => {
  const { isTabletOrSmaller } = useMediaQueries();
  const { userStore, ui } = useStore();
  const { errorToast } = useToast();
  const [activeEstablishment, setActiveEstablishment] = useState(() => {
    return userStore.roles.find(
      role => role._establishment_id === userStore.currentRole._establishment_id,
    );
  });
  const [searchStr, setSearchStr] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const handleClick = useCallback(
    event => {
      setOpen(prevState => !prevState);
      ui.setEstablishmentMenu(!ui.isOpenedEstablishmentMenu);

      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [ui, anchorEl],
  );

  const handleClose = useCallback(
    event => {
      event.preventDefault();
      ui.setEstablishmentMenu(false);

      setSearchStr('');
      setOpen(false);
      setAnchorEl(null);
    },
    [ui],
  );

  useEffect(() => {
    userStore.setSearchString(searchStr);
  }, [userStore, searchStr]);

  const onSearch = useCallback(value => {
    setSearchStr(value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchStr('');
  }, []);

  const onChange = useCallback(
    async role => {
      try {
        setActiveEstablishment(role);
        const establishment = toJS(role);
        await userStore.changeEstablishment(establishment);
      } catch (err) {
        errorToast(messages.establishment.CHANGE_FAILED);
      }
    },
    [userStore, errorToast],
  );

  useEffect(() => {
    if (activeEstablishment?._establishment_id) {
      const index = userStore
        .filteredRolesBySearch(searchStr)
        .findIndex(item => item._establishment_id === activeEstablishment._establishment_id);
      const itemHeight = 32; // Menu item height in px
      if (ref?.current) {
        ref.current.scrollTo({ top: itemHeight * index });
      }
    }
  }, [ref, anchorEl, userStore, searchStr, activeEstablishment]);

  const paperStyles = useMemo(() => {
    if (!inputRef.current || !anchorEl) return;

    const coords = inputRef.current.getBoundingClientRect();

    if (isTabletOrSmaller) {
      return {
        top: `${coords.bottom}px`,
        left: 0,
      };
    }

    return {
      top: `${coords.bottom}px`,
      right: `${document.body.offsetWidth - coords.right}px`,
    };
  }, [anchorEl, isTabletOrSmaller]);

  const filteredRoles = userStore.filteredRolesBySearch(searchStr);

  return (
    <StyledRootBox>
      <Button
        onClick={handleClick}
        endIcon={Boolean(open) ? <ExpandLess /> : <ExpandMore />}
        className="label"
        ref={inputRef}
      >
        <span className="truncate-text">{activeEstablishment?._establishment_name}</span>
      </Button>
      {Boolean(open) && (
        <ClickAwayListener onClickAway={handleClose}>
          <Paper ref={ref} className="menu" sx={{ ...paperStyles }}>
            <Box px={1} pt={1} className="search">
              <SearchField onChange={onSearch} onClear={clearSearch} autoFocus />
            </Box>
            <MenuList>
              {filteredRoles.length > 0 ? (
                filteredRoles.map(role => {
                  return (
                    <MenuItem key={role._role_id} value={role} onClick={() => onChange(role)}>
                      <Typography
                        noWrap
                        style={{
                          fontWeight:
                            role._establishment_id === activeEstablishment._establishment_id
                              ? 'bold'
                              : 'inherit',
                        }}
                        className="option left"
                      >
                        {role._establishment_name}
                      </Typography>
                    </MenuItem>
                  );
                })
              ) : (
                <Box pt={1}>
                  <Typography variant="body1" className="noResults">
                    No roles found for '{searchStr}'
                  </Typography>
                </Box>
              )}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      )}
    </StyledRootBox>
  );
};

export default observer(EstablishmentMenu);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  '@media(max-width: 350px)': {
    maxWidth: '260px',
  },
  '.truncate-text': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media(min-width: 500px)': {
      maxWidth: '100%',
    },
    '@media(max-width: 470px)': {
      maxWidth: '260px',
    },
    '@media(max-width: 400px)': {
      maxWidth: '240px',
    },
    '@media(max-width: 350px)': {
      maxWidth: '200px',
    },
  },
  '.label': {
    textTransform: 'initial!important',
    letterSpacing: '-0.05px!important',
    whiteSpace: 'nowrap',
  },
  '.search': {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    background: palette.secondary.light,
  },
  '.menu': {
    position: 'absolute',
    height: 'fit-content',
    maxHeight: '200px',
    width: 450,
    overflow: 'auto',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    background: palette.secondary.light,
    zIndex: 1100,

    '& .MuiList-padding': {
      paddingTop: 0,
    },

    '& .MuiList-root': {
      background: palette.secondary.light,
    },
    '@media(max-width: 1023px)': {
      width: '90%',
      marginLeft: '5%',
    },
  },
  '.noResults': {
    textAlign: 'center',
  },
}));
