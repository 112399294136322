import containerTypes from './containerTypes';

export const messages = {
  common: {
    success: 'The operation was successful',
    error: 'An error has occurred',
    PROCESS_WARNING: 'The previous request is still being processed',
    ESTABLISHMENT_DATA_LOAD_ERROR:
      'There was an error loading some core data - some screens might not work correctly',
    INVALID_CHARS: 'Invalid character(s) entered',
  },

  sku: {
    UPDATE_GLASSWARE_SUCCESS: 'The Serving Size values were successfully updated',
    UPDATE_GLASSWARE_FAILURE: 'There was an error updating the Serving Size values',
    UPDATE_KEG_SUCCESS: 'The Keg Size values were successfully updated',
    UPDATE_KEG_FAILURE: 'There was an error updating the Keg Size values',
    CREATE_GLASSWARE_WARNING: 'No Serving Sizes have been set-up in System Configuration',
    CREATE_GLASSWARE_SUCCESS: 'The Serving Size was successfully added',
    CREATE_GLASSWARE_FAILURE: 'There was an error creating the Serving Size',
    CREATE_KEG_WARNING: 'No Keg Sizes have been set-up in System Configuration',
    CREATE_KEG_SUCCESS: 'The Keg Size was successfully added',
    CREATE_KEG_FAILURE: 'There was an error creating the Keg Size',
    DELETE_GLASSWARE_SUCCESS: 'The Serving Size was successfully deleted',
    DELETE_GLASSWARE_FAILURE_GENERAL: 'There was an error deleting the Serving Size',
    DELETE_GLASSWARE_FAILURE_LINKED:
      'Unable to delete this Serving Size - it is currently mapped to at least one POS SKU',
    DELETE_KEG_SUCCESS: 'The Keg Size was successfully deleted',
    DELETE_KEG_FAILURE_GENERAL: 'There was an error deleting the Keg Size',
    // DELETE_KEG_FAILURE_LINKED: 'Unable to delete this Keg Size - it has been linked to at least one keg',
    KEG_DEFAULT_GOAL_SUCCESS: 'The Keg Level was successfully updated',
    KEG_DEFAULT_GOAL_ERROR: 'There was an error updating the Keg Cost',
    KEG_DEFAULT_COST_SUCCESS: 'The Keg Level was successfully updated',
    KEG_DEFAULT_COST_ERROR: 'There was an error updating the Keg Cost',
    KEG_DEFAULT_PAR_LEVEL_SUCCESS: 'The PAR Level was successfully updated',
    KEG_DEFAULT_PAR_LEVEL_ERROR: 'There was an error updating the PAR Level',
    GLASSWARE_HEAD_PERCENTAGE_SUCCESS:
      'The Head Percentage was successfully updated for the Serving Size',
    GLASSWARE_HEAD_PERCENTAGE_ERROR:
      'There was an error updating the Head Percentage for the Serving Size',
    GLASSWARE_DISPLAY_PRICE_SUCCESS:
      'The Display Price was successfully updated for the Serving Size',
    GLASSWARE_DISPLAY_PRICE_ERROR:
      'There was an error updating the Display Price for the Serving Size',
    GLASSWARE_DISPLAY_NAME_SUCCESS:
      'The Display Name was successfully updated for the Serving Size',
    GLASSWARE_DISPLAY_NAME_ERROR:
      'There was an error updating the Display Name for the Serving Size',
    GLASSWARE_HIDDEN_SUCCESS: 'The Serving Size was successfully disabled',
    GLASSWARE_HIDDEN_ERROR: 'There was an error disabling the Serving Size',
    GLASSWARE_REORDER_SUCCESS: 'The Serving Sizes were successfully re-ordered',
    GLASSWARE_REORDER_ERROR: 'There was an error re-ordering the Serving Sizes',
  },

  cleaning: {
    START_SUCCESS: 'Cleaning Mode was successfully enabled',
    START_FAILURE: 'There was an error enabling Cleaning Mode',
    STOP_SUCCESS: 'Cleaning Mode was successfully disabled',
    STOP_FAILURE: 'There was an error disabling Cleaning Mode',
    START_ALL_SUCCESS: 'Cleaning Mode successfully enabled for all lines',
    START_ALL_FAILURE: 'There was an error enabling Cleaning Mode',
    STOP_ALL_SUCCESS: 'Cleaning Mode was successfully disabled for all lines',
    STOP_ALL_FAILURE: 'There was an error disabling Cleaning Mode',
    CLEANING_ADDED_SUCCESS: 'The Missed Cleaning(s) was successfully added',
    CLEANING_ADDED_ERROR: 'There was an error adding the Missed Cleaning(s)',
    CLEANING_UPDATED_SUCCESS: 'The Line Cleaning(s) were successfully updated',
    CLEANING_UPDATED_ERROR: 'There was an error updating the Line Cleaning(s)',
    CLEANING_REMOVED_SUCCESS: 'The Line Cleaning(s) were successfully removed',
    CLEANING_REMOVED_ERROR: 'There was an error removing the Line Cleaning(s)',
    CLEANING_SCHEDULED_SUCCESS: 'The Line Cleaning due date was successfully scheduled',
    CLEANING_SCHEDULED_ERROR: 'There was an error setting the Line Cleaning due date',
  },

  systemConfiguration: {
    HOUR_CREATE_SUCCESS: 'Record has been successfully created.',
    HOUR_CREATE_FAILED: 'There was an error to create a new record.',
    SCHEDULE_DELETE_SUCCESS: 'Record has been successfully removed.',
    SCHEDULE_DELETE_FAILED: 'There was an error removing a record.',
  },

  containers: {
    [containerTypes.Keg]: {
      CREATE_SUCCESS: 'The Keg Size was successfully added',
      CREATE_FAILURE: 'There was an error creating the Keg Size',
      UPDATE_SUCCESS: 'The Keg Size values were successfully updated',
      UPDATE_FAILURE: 'There was an error updating the Keg Size values',
      DELETE_SUCCESS: 'The Keg Size was successfully deleted',
      DELETE_FAILURE_GENERAL: 'There was an error deleting the Keg Size',
      DELETE_FAILURE_LINKED:
        'Unable to delete this Keg Size - it has been linked to at least one keg',
      VOLUME_SUCCESS: 'The Volume associated with the Keg Size was successfully updated',
      VOLUME_ERROR: 'There was an error updating the Volume associated with the Keg Size',
      UNIT_SUCCESS: 'The Volume Unit associated with the Keg Size was successfully updated',
      UNIT_ERROR: 'There was an error updating the Volume Unit associated with the Keg Size',
      NAME_SUCCESS: 'The Display Name associated with the Keg Size was successfully updated',
      NAME_ERROR: 'There was an error updating the Display Name associated with the Keg Size',
      ACTIVE_SUCCESS: 'The Keg Size was successfully set as active',
      ACTIVE_ERROR: 'There was an error setting the Keg Size as active',
      INACTIVE_SUCCESS: 'The Keg Size was successfully set as inactive',
      INACTIVE_ERROR: 'There was an error setting the Keg Size as inactive',
    },
    [containerTypes.Draft]: {
      CREATE_SUCCESS: 'The Serving Size was successfully added',
      CREATE_FAILURE: 'There was an error creating the Serving Size',
      UPDATE_SUCCESS: 'The Serving Size values were successfully updated',
      UPDATE_FAILURE: 'There was an error updating the Serving Size values',
      DELETE_SUCCESS: 'The Serving Size was successfully deleted',
      DELETE_FAILURE_GENERAL: 'There was an error deleting the Serving Size',
      DELETE_FAILURE_LINKED:
        'Unable to delete this Serving Size - it is currently mapped to at least one POS SKU',
      VOLUME_SUCCESS: 'The Volume associated with the Serving Size was successfully updated',
      VOLUME_ERROR: 'There was an error updating the Volume associated with the Serving Size',
      UNIT_SUCCESS: 'The Volume Unit associated with the Serving Size was successfully updated',
      UNIT_ERROR: 'There was an error updating the Volume Unit associated with the Serving Size',
      NAME_SUCCESS: 'The Display Name associated with the Serving Size was successfully updated',
      NAME_ERROR: 'There was an error updating the Display Name associated with the Serving Size',
      ACTIVE_SUCCESS: 'The Serving Size was successfuly set as active',
      ACTIVE_ERROR: 'There was an error setting the Serving Size as active',
      INACTIVE_SUCCESS: 'The Serving Size was successfully set as inactive',
      INACTIVE_ERROR: 'There was an error setting the Serving Size as inactive',
    },

    UNIT_CHANGE_SUCCESS: 'The units were successfully converted',
    UNIT_CHANGE_FAILURE: 'There was an error converting the units',
    CHANGE_VOLUME_UNIT:
      'Adjusting the volume for a Serving Size may significantly impacts your analytical reports. Are you sure that you would like to proceed?',
  },

  keg_change: {
    CHANGE_SUCCESS: 'The keg was successfully connected',
    CHANGE_FAILURE: 'There was an error connecting the keg',
    NEGATIVE_VOLUME: 'Unable to connected a keg with negative volume remaining',
    DISCARD_SUCCESS: 'The keg was successfully removed',
    DISCARD_FAILURE: 'There was an error removing the keg',
    STORE_SUCCESS: 'The keg was successfully saved to your Inventory',
    STORE_FAILURE: 'There was an error saving the keg to your Inventory',
    GENERIC_FAILURE: 'An unknown error occurred - please try again',
    KEG_CORRECTION_SUCCESS: 'The Keg Correction was successfully processed',
    KEG_CORRECTION_ERROR: 'There was an error when processing the Keg Correction',
    GET_POURS_FAILED: 'There was an error getting pours',
    noResponse: {
      KEG_CORRECTION: 'There was an error getting a status of the Keg Correction',
    },
  },

  item: {
    TAP_WARNING: 'You should first tap item to the line', // no clue what this does
    ADD_TO_INVENTORY_SUCCESS: 'The keg(s) were successfully added to Inventory',
    ADD_TO_INVENTORY_FAILURE: 'There was an error adding the keg(s) to Inventory',
    REMOVE_FROM_INVENTORY_SUCCESS: 'The keg(s) were successfully removed from Inventory',
    REMOVE_FROM_INVENTORY_FAILURE: 'There was an error removing the keg(s) from Inventory',
    REMOVE_FROM_QUEUE_SUCCESS: 'The keg was successfully removed from the queue',
    REMOVE_FROM_QUEUE_FAILURE: 'There was an error removing the keg from the queue',
    ADD_TO_QUEUE_SUCCESS: 'The keg was successfully added to the queue',
    ADD_TO_QUEUE_FAILURE: 'There was an error removing the keg from the queue',
    UPDATE_KEG_SUCCESS: 'The keg was successfully updated',
    UPDATE_KEG_FAILURE: 'There was an error updating the keg',
    UPDATE_INVENTORY_SUCCESS: 'All eligible kegs in Inventory were successfully updated',
    UPDATE_INVENTORY_FAILURE: 'There was an error updating the kegs in Inventory',
    UPDATE_HISTORIC_SUCCESS: 'All eligible historic kegs were successfully updated',
    UPDATE_HISTORIC_FAILURE: 'There was an error updating the historic kegs',
    KEG_LEVEL_SUCCESS: 'The Keg Level was successfully updated',
    KEG_LEVEL_ERROR: 'Unable to adjust Keg Level - please reload the page and try again',
    KEG_SIZE_SUCCESS: 'The Keg Size was successfully updated',
    KEG_SIZE_ERROR: 'There was an error updating the Keg Size',
    KEG_GOAL_SUCCESS: 'The Keg Goal was successfully updated',
    KEG_GOAL_ERROR: 'There was an error updating the Keg Goal',
    KEG_COST_SUCCESS: 'The Keg Cost was successfully updated',
    KEG_COST_ERROR: 'There was an error updating the Keg Cost',
    KEG_PAR_LEVEL_SUCCESS: 'The PAR Level was successfully updated',
    KEG_PAR_LEVEL_ERROR: 'There was an error updating the PAR Level',
    INVENTORY_SAVED_SUCCESS: 'Inventory Successfully Saved',
    INVENTORY_SAVED_ERROR: 'Error Saving Inventory',
  },

  queue: {
    ADD_SUCCESS: 'The keg was successfully added to the queue',
    ADD_FAILED: 'There was an error adding the keg to the queue',
    REORDER_SUCCESS: 'The order of the queue was successfully adjusted',
    REORDER_FAILED: 'There was an error adjusting the queue',
    REMOVE_SUCCESS: 'The keg was successfully removed from the queue',
    REMOVE_FAILED: 'There was an error removing the keg from the queue',
  },

  login: {
    ERROR_401:
      'We were unable to verify your email and/or password - please check the information and try again',
    ERROR_UNAVAILABLE: 'Server Unavailable - please try again later',
  },

  network: {
    ERROR_401: 'You do not have permission to access this resource',
  },

  establishment: {
    UPDATE_SUCCESS: 'System Configuration was successfully updated',
    UPDATE_FAILED: 'There was an error updating System Configuration',
    CHANGE_FAILED: 'There was an error switching locations - please try again',
  },

  validation: {
    PASSWORD_ERROR: 'Password should be at least 7 characters long',
    PASSWORD_RESET_FAILED: 'Unable to reset password - please try again',
  },

  contact: {
    MESSAGE_SENT_SUCCESS: 'Your message was successfully sent',
    MESSAGE_SENT_FAILED: 'There was an error sending your message',
  },

  beverages: {
    CREATE_SUCCESS: 'The beverage was successfully added',
    CREATE_FAILED: 'There was an error adding the beverage',
    UPDATE_SUCCESS: 'The beverage was successfully updated',
    UPDATE_FAILED: 'There was an error updating the beverage',
    ARCHIVE_SUCCESS: 'The beverage was successfully archived',
    ARCHIVE_FAILED: 'There was an error archiving the beverage',
    UNARCHIVE_SUCCESS: 'The beverage was successfully un-archived',
    UNARCHIVE_FAILED: 'There was an error un-archiving the beverage',
    SEARCH_FAILED: 'No beverages found - try modifying your search',
    COPY_FAILED: 'There was an error cloning the beverage',
    GET_PRODUCERS_FAILED: 'There was an error getting the list of Producers',
    PRODUCER_CREATED_SUCCESS: 'The producer was successfully added',
    PRODUCER_CREATED_ERROR: 'There was an error adding the producer',
  },

  user_management: {
    ACCEPT_GRANT_SUCCESS: 'The invitation was successfully accepted',
    ACCEPT_GRANT_FAILED: 'There was an error accepting the invitation',
    GRANTS_MADE_SUCCESS: 'The role was successfully changed for the user',
    CHANGED_ROLES_FAILED: 'There was an error modifying the role assigned to the user',
    ARCHIVED_USER_SUCCESS: 'The user was successfully archive',
    ARCHIVED_USER_FAILED: 'There was an error archiving the user',
    UNARCHIVED_USER_SUCCESS: 'The user was successfully un-archive',
    UNARCHIVED_USER_FAILED: 'There was an error un-archiving the user',
    USER_INFO_UPDATED_SUCCESS: 'The account details were successfully updated',
    USER_INFO_UPDATED_FAILED: 'There was an error updating the account details',
    ADD_USER_SUCCESS: 'The user was successfully added',
    ADD_USER_FAILED: 'There was an error adding the user(s)',
    FORGOT_PASSWORD_SUCCESS:
      'If a BarTrack account was found with the provided email address, password reset instructions were sent',
    FORGOT_PASSWORD_FAILED: 'No valid BarTrack accounts found with the provided email address',
    RESET_PASSWORD_FAILED: 'There was an error resetting the password',
    UPDATE_EMAIL_FAILED: 'There was an error updating the email',
    UPDATE_EMAIL_SUCCESS: 'Your Email Address has successfully been updated',
    UPDATE_PASSWORD_SUCCESS: 'Your Password has successfully been updated',
  },

  pos_skus: {
    UNMATCH_ITEMS: 'Are you sure you want to un-link these SKUs?',
    IGNORE_ITEMS:
      'Are you sure you want to move this product into the Ignored Items list? All ignored items will be moved to the “Ignored Items” section.',
    UNLINK_SUCCESS: 'The SKU(s) were successfully un-linked',
    UNLINK_ERROR: 'There was an error un-linking the SKU(s)',
    LINK_SUCCESS: 'The SKU(s) were successfully linked',
    LINK_ERROR: 'There was an error linking the SKU(s)',
    IGNORE_SUCCESS: 'The SKU was successfully ignored',
    IGNORE_ERROR: 'There was an error ignoring the SKU',
    UNIGNORE_SUCCESS: 'The SKU was successfully removed from the Ignore List',
    UNIGNORE_ERROR: 'There was an error removing the SKU from the Ignore List',
  },

  bm_correction: {
    KEG_LEVEL_SUCCESS: 'The Keg Level was successfully updated',
    KEG_LEVEL_ERROR: 'There was an error updating the Keg Level',
    KEG_SIZE_SUCCESS: 'The Keg Size was successfully updated',
    KEG_SIZE_ERROR: 'There was an error updating the Keg Size',
    GOAL_SUCCESS: 'The Keg Goal was successfully updated',
    GOAL_ERROR: 'There was an error updating the Keg Goal',
    COST_SUCCESS: 'The Keg Cost was successfully updated',
    COST_ERROR: 'There was an error updating the Keg Cost',
    CLEANING_ADDED_SUCCESS: 'The Missed Cleaning(s) was successfully added',
    CLEANING_ADDED_ERROR: 'There was an error adding the Missed Cleaning(s)',
    CLEANING_UPDATED_SUCCESS: 'The Line Cleaning(s) were successfully updated',
    CLEANING_UPDATED_ERROR: 'There was an error updating the Line Cleaning(s)',
    CLEANING_REMOVED_SUCCESS: 'The Line Cleaning(s) were successfully removed',
    CLEANING_REMOVED_ERROR: 'There was an error removing the Line Cleaning(s)',
    SWAP_SUCCESS: 'The kegs were successfully swapped',
    SWAP_ERROR: 'There was an error swapping the kegs',
  },

  report_management: {
    GENERATE_SUCCESS: 'The report(s) were successfully generated',
    GENERATE_ERROR: 'There was an error generating the report(s)',
  },

  digital_menu: {
    ACTIVATE_SUCCESS: 'The Token was successfully validated',
    ACTIVATE_FAILED: 'There was an error validating the Token',
    ACTIVATE_FAILED_MAX_CHARS: 'Token validation failed - too many characters',
    ACTIVATE_FAILED_MIN_CHARS: 'Token validation failed - too few characters',
    QRCODE_GENERATION_SUCCESS: 'The QR Code was successfully generated',
    QRCODE_GENERATION_FAILED: 'There was an error generating the QR Code',
  },

  menus: {
    CREATE_SUCCESS: 'The Menu was successfully added',
    CREATE_ERROR: 'There was an error adding the Menu',
    UPDATE_SUCCESS: 'The Menu was successfully updated',
    UPDATE_ERROR: 'There was an error updating the Menu',
    DELETE_SUCCESS: 'The Menu was successfully deleted',
    DELETE_ERROR: 'There was an error deleting the Menu',
    RELOAD_ALL_SUCCESS: 'The Menu has successfully been reloaded',
    RELOAD_ALL_ERROR: 'There was an error reloading the Menu',
  },

  menu_designs: {
    CREATE_SUCCESS: 'The Menu Design was successfully added',
    CREATE_ERROR: 'There was an error adding the Menu Design',
    UPDATE_SUCCESS: 'The Menu Design was successfully updated',
    UPDATE_ERROR: 'There was an error updating the Menu Design',
    DELETE_SUCCESS: 'The Menu Design was successfully deleted',
    DELETE_ERROR: 'There was an error deleting the Menu Design',
    EDITOR_ID_ERROR: 'Unable to access editor due to missing parameters',
    ADD_TO_MENU_SUCCESS: 'The Menu Design was successfully added to the Menu',
    ADD_TO_MENU_ERROR: 'There was an error adding the Menu Design to the Menu',
    REMOVE_FROM_MENU_SUCCESS: 'The Menu Design was successfully removed from the Menu',
    REMOVE_FROM_MENU_ERROR: 'There was an error removing the Menu Design from the Menu',
    IS_USED_BY_MENU: 'This Menu Design is currently being used by a Menu',
  },

  menu_lists: {
    CREATE_SUCCESS: 'The Beverage List was successfully added',
    CREATE_ERROR: 'There was an error adding the Beverage List',
    UPDATED_SUCCESS: 'The Beverage List was successfully updated',
    UPDATED_ERROR: 'There was an error updating the Beverage List',
    DELETE_SUCCESS: 'The Beverage List was successfully deleted',
    DELETE_ERROR: 'There was an error deleting the Beverage List',
    REMOVE_SUCCESS: 'The Beverage List List was successfully removed',
    REMOVE_ERROR: 'There was an error removing the Beverage List List',
    ADD_TO_MENU_SUCCESS: 'The Menu List was successfully added to the Menu',
    ADD_TO_MENU_ERROR: 'There was an error adding the Menu List to the Menu',
    REMOVE_FROM_MENU_SUCCESS: 'The Beverage List was successfully removed from the Menu',
    REMOVE_FROM_MENU_ERROR: 'There was an error removing the Beverage List from the Menu',
    IS_USED_BY_MENU: 'This Menu List is currently being used by a Menu',
  },
  bars: {
    ASSIGN_SUCCESS: 'The user successfully assigned to the bar',
    ASSIGN_ERROR: 'There was an error assigning the user to the bar',
    UNASSIGN_SUCCESS: 'The user successfully unassigned from the bar',
    UNASSIGN_ERROR: 'There was an error unassigning the user from the bar',
    DEFAULT_BAR_SUCCESS: 'Default bar successfully changed',
    DEFAULT_BAR_ERROR: 'There was an error changing default bar',
  },
  swap: {
    TAP_SUCCESS: 'The Tap Numbers were successfully swapped',
    TAP_ERROR: 'An unexpected error occurred while swapping the Tap Numbers',
    LINE_SUCCESS: 'The Line Numbers were successfully swapped',
    LINE_ERROR: 'An unexpected error occurred while swapping the Line Numbers',
    BEVERAGE_SUCCESS: 'The Kegs were successfully swapped',
    BEVERAGE_ERROR: 'An unexpected error occurred while swapping the Kegs',
  },
  sensor: {
    UPDATE_SUCCESS: 'Sensor updated successfully',
    UPDATE_ERROR: 'Error updating sensor',
    FETCH_ERROR: 'An unexpected error occurred while fetching Sensor Data',
    CALIBRATE_ERROR: 'An unexpected error occurred while setting calibration value(s)',
    CLEAR_SENSORS_SUCCESS: 'Sensors cleared successfully',
    CLEAR_SENSORS_ERROR: 'Error clearing sensors',
  },
  bru: {
    REPLACE_SUCCESS: 'BRU replaced successfully',
    REPLACE_ERROR: 'Error replacing BRU',
  },
  gateway: {
    LOCATION_UPDATE_SUCCESS: 'Gateway installation location updated successfully',
    LOCATION_UPDATE_ERROR: 'Error updating gateway installation location',
    CONNECTION_TYPE_UPDATE_SUCCESS: 'Gateway connection type updated successfully',
    CONNECTION_TYPE_UPDATE_ERROR: 'Error updating gateway connection type',
  },
};
